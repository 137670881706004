import { useState } from 'react';
import app from '../model/connection'
import { getDatabase, ref, set, get, update, remove } from "firebase/database";

const database = getDatabase(app);

function LoginPage (){
  // State for storing email and password
  const [Email, SetEmail] = useState('');
  const [Password, SetPassword] = useState('');

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    const dbRef = ref(database, 'user/' + Email);
    try {
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        if(snapshot.val()['password']===Password){
          console.log("authenticated")
        }
      } else {
        console.log('No user data available');
        return null;
      }
    } catch (error) {
      console.error('Error checking user data:', error);
      return null;
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px', border: '1px solid #ddd', borderRadius: '8px' }}>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="email" style={{ display: 'block', marginBottom: '5px' }}>Email:</label>
          <input
            type="email"
            id="email"
            value={Email}
            onChange={(e) => SetEmail(e.target.value)}
            requiredITS
            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="password" style={{ display: 'block', marginBottom: '5px' }}>Password:</label>
          <input
            type="password"
            id="password"
            value={Password}
            onChange={(e) => SetPassword(e.target.value)}
            required
            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </div>
        <button
          type="submit"
          style={{ width: '100%', padding: '10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '4px' }}
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
