import './App.css';
import LoginPage from './view/loginpage';

function App() {
  return (
    <div className="App">
      <LoginPage/>
    </div>
  );
}

export default App;
